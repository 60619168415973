/* eslint-disable react/prop-types */
import React from "react";
import { graphql } from "gatsby";
import GlobalLayout from "../global/GlobalLayout";
import ArticleBody from "../content/ArticleBody";
import { IContentTableOfContentsLink } from "../content/ContentTableOfContents";

interface IArticle {
  data: IArticleQuery;
}
  
const Article = ({ data }: IArticle): JSX.Element => {
  const { createdDate, bodyHtml } = data.confluencePage.articleData;

  return (
    <GlobalLayout siteMetadata={data.site.siteMetadata} smoothScroll={true}>
      <ArticleBody {...data.confluencePage.articleData} createdDate={new Date(createdDate)} html={bodyHtml}/>
    </GlobalLayout>
  );
};
export default Article;

interface IArticleQuery {
  site: {
    siteMetadata: {
      name: string;
      description: string;
    }
  }
  confluencePage: {
    articleData: {
      title: string;
      labels: string[];
      createdDate: string;
      readTime: number;
      anchors: IContentTableOfContentsLink[];
      bodyHtml: string;
    }
  }
}
  
export const query = graphql`
  query Article($id: String) {
    site {
      siteMetadata {
        name
        description
      }
    }
    confluencePage(id: { eq: $id }) {
      articleData {
        title
        labels
        createdDate
        readTime
        anchors {
          slug
          title
          isChild
        }
        bodyHtml
      }
    }
  }
`;